@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

mark {
  background-color: #097CC157;
}

.help-icon {
  color: #097CC1;
  width: 42px;
  height: 42px;
  cursor: pointer;

}

.sui-search-box {
  align-items: center !important;
}

.sui-search-box__wrapper {

}

.ptn-logo {
  width: auto;
  height: 70px;
}

.help-example {
  width: 100%;
  height: auto;
}

.argentina-logo {
  width: auto;
  height: 50px;
}

.App {
  position: relative;
  background: #FFFFFF;
  height: 100%;
  min-height: 60vh;
}

header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 100px;
  padding: 10px;
  background-color: #323C47;
  align-items: center;
}

header a:link, header a:visited {
  color: #FFFFFF;
}

.header-subtitle {
  position: relative;
  height: 6px;
  background-color: #097CC1;
}

.App:before {
  content:"";
  background:#FFFFFF;
  position: absolute;
  height: 100%;
  width: 200vw;
  left: -100vw;
  z-index: -1;
}

.header-subtitle:before {
    content:"";
    background: #097CC1;
    position: absolute;
    height: 100%;
    width: 200vw;
    left: -100vw;
    z-index: -1;
}

header:before {
    content:"";
    background: #323C47;
    position: absolute;
    height: 100%;
    width: 200vw;
    left: -100vw;
    z-index: -1;
    /* top: 0vw; */
}

.header-base-layout {
  width: 76%;
  align-self: flex-end;
  padding: 0 32px;
}

.sui-layout-header {
  background:#FFFFFF !important;
  padding: 10px 0px !important;
}

.sui-layout-header__inner {
  display: flex;
  flex-flow: column nowrap;
}

.sui-layout-main {
  background: inherit !important;
  padding-right: 24px !important;
}

.sui-layout-main-header {
  padding: 0 20px !important;
}

.sui-layout {
  background: inherit !important;
}

.sui-facet-search__text-input, .sui-facet-search__text-input:hover {
  background-color:#F5F6F8 !important;
  box-shadow: 2px 2px rgba(0,0,0,0.2);
  border: 0 !important;
}

input.sui-search-box__text-input {
  background-color:#F5F6F8 !important;
  background: url('https://api.iconify.design/ant-design:search-outlined.svg?color=%23C2CFE0&height=32') no-repeat scroll 14px 50%;
  padding-left:50px;
  box-shadow: 2px 2px rgba(0,0,0,0.2);
  border: 0;
}

.sui-search-box__text-input:focus{
  box-shadow: 2px 2px rgba(0,0,0,0.2) !important;
  border: 0 !important;
}

.sui-layout-body__inner {
  background: inherit !important;
  padding: 0px !important;
}

.sui-layout-body {
  background: inherit !important;
}

.sui-layout-sidebar {
  background:#FFFFFF !important;
  z-index: 1;
  padding: 0 !important;
}

.sidebar-base {
  padding-left: 24px !important;
  -moz-box-shadow:    3px 0 2px -2px rgba(0,0,0,0.2);
  -webkit-box-shadow: 3px 0 2px -2px rgba(0,0,0,0.2);
  box-shadow:         3px 0 2px -2px rgba(0,0,0,0.2);
  padding: 32px 32px 0 0;
}

.sui-layout-body:after {
  background:#FFFFFF !important;
}

.footer {
  margin: 25px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #097CC1;
  max-width: 100%;
}

.footer:before {
    content:"";
    background: #097CC1;
    position: absolute;
    height: 100%;
    width: 200vw;
    left: -100vw;
    z-index: -1;
}

footer p {
  text-align: left;
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 9pt;
}

footer a {
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 9pt;
  text-align: left;
  text-decoration: none;
}
.loader-container {
  position: absolute;
  left: 50%;
}

.loader {
  position: relative;
  left: -50%;
  width: 500px;
  margin-top: 50px;
  background-color: #FFFFFF;
}

.loader:before {
  content:"";
  background:#FFFFFF;
  position: absolute;
  height: 100%;
  width: 200vw;
  left: 0vw;
  top: 0vw
}

.sui-result__title {
  font-size: 1em !important;
  font-weight: 550;
}

.result-root {
  flex-grow: 1;
}

.result-paper {
  padding: 10px;
  margin: 10px 0;
  max-width: 500;
}

.result-image {
  width: 128;
  height: 128;
}

.result-img {
  margin: 'auto';
  display: 'block';
  max-width: '100%';
  max-height: '100%';
}

.MuiIcon-colorPrimary {
  color: #F5F6F8;
}

.sui-facet-view-more:hover {
  background: none !important;
  outline: none !important;

}

.sui-search-box__submit {
  background: #097CC1 !important;
  box-shadow: 2px 2px rgba(0,0,0,0.2) !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

.sui-search-box__submit:hover {
  background: #097CC1 !important;
  opacity: 0.9;
  border: 0 !important;
  box-shadow: 2px 2px rgba(0,0,0,0.2) !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

.body-home{
  display: flex;
  padding: 50px;
  flex-flow: column nowrap;
}

.body-home .help-icon {
  color: #097CC1;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.read-more-button {
  cursor: pointer;
}

html, body {margin: 0; padding: 0; overflow-x:hidden;}

.body-help-poup:hover .help-icon {
  color: #52ACE1 !important;
}

.body-help-poup:hover .MuiTypography-root {
  color: #52ACE1 !important;
}

.grecaptcha-badge { 
  visibility: hidden; 
}

.filter ul label span{
  color: #707683;
  font-size: 10pt;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.235 !important;
  letter-spacing: 0.00735em;
}

.filter ul{
  /* max-height: 135px; */
}

.filter .filter-title {
  color: #707683;
  font-size: 12pt;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  line-height: 1.6 !important;
  letter-spacing: 0.0075em;
}

.filter .filter-search {
  font-size: 10pt;
}

.filter li {
  margin-right: 5px;
}

.filter ul::-webkit-scrollbar {
  -webkit-appearance: none;
}

.popup-content {
  align-items: center !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
